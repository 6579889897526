import { memo, useCallback, useRef, useState } from "react";
import Cropper, { Area } from "react-easy-crop";
import { cropImage } from "./utils/cropImage.utils";
import { Box, Button, FileInput, Modal } from "@mantine/core";
import { IconCrop, IconDeviceFloppy } from "@tabler/icons-react";

const ImageCropPicker = (props: TImageCropPickerModal) => {
  const { open, close, data } = props;
  const { aspect, onSaveCallback } = data;
  const [image, setImage] = useState<File | null>(null);
  const [filename, setFileName] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });
  const fileRef = useRef<HTMLInputElement>(null);

  const [croppedImg, setCroppedImg] = useState<Blob | undefined>();

  const handleClose = () => {
    close(!open);
    setImage(null);
    if (fileRef.current) {
      fileRef.current.files = null;
      fileRef.current.value = "";
    }
    setCroppedImg(undefined);
  };

  const onCropImgClick = async (imageUrl: string) => {
    // console.log("image", imageUrl);
    if (image) {
      let cropped = await cropImage(
        image,
        imageUrl,
        croppedAreaPixels,
        console.log
      );
      setCroppedImg(cropped);
      setFileName(image.name);
    }
  };

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  return (
    <Modal
      size={"xl"}
      trapFocus={false}
      opened={open}
      onClose={handleClose}
      title={"Image Picker"}
    >
      <Box my={15}>
        <FileInput
          placeholder="Choose File"
          label="Select Image"
          withAsterisk
          accept="image/*"
          onChange={(e) => {
            if (e !== null) {
              setImage(e);
            } else {
              setImage(null);
            }
          }}
        />
      </Box>
      {image && (
        <>
          <Box style={{ position: "relative", height: 250 }}>
            <Cropper
              image={URL.createObjectURL(image)}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </Box>
          <Box mt={10}>
            <Button
              onClick={() => onCropImgClick(URL.createObjectURL(image))}
              color="cyan"
            >
              <IconCrop style={{ fontSize: 18, marginRight: 5 }} />
              Crop
            </Button>
          </Box>
        </>
      )}
      {croppedImg && (
        <Box p={2} my={10}>
          <img
            width={"100%"}
            src={URL.createObjectURL(croppedImg)}
            alt="croppedImg"
          />
          <Box mt={2} style={{ textAlign: "center" }}>
            <Button
              onClick={() => {
                onSaveCallback &&
                  onSaveCallback({ croppedImg, filename: filename });
                handleClose();
              }}
              style={{ marginLeft: 1 }}
              color="blue"
            >
              <IconDeviceFloppy style={{ fontSize: 18, marginRight: 5 }} />
              Save
            </Button>
          </Box>
        </Box>
      )}
    </Modal>
  );
};

export default memo(ImageCropPicker);
