import { useMutation, useQueryClient } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { TServerResponse } from "../../../Types";
import { apiUrls } from "../../api-urls";

interface IData {
  _id: string;
}

const deleteEvent = async (data: IData) => {
  const res: TServerResponse = await request({
    url: apiUrls.DELETE_EVENT + `/${data._id}`,
    method: "DELETE",
  });
  return res;
};

export const usedelteEventMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteEvent,
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["events", "paginated"] });
      return true;
    },
  });
};
