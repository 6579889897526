import { Box, Button, Grid } from "@mantine/core";
import React, { memo } from "react";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import classes from "./index.module.css";
import { ROUTES } from "../../enum/routes";
import { IMAGES } from "../../assets/Images";

const Header = () => {
  const signOut = useSignOut();
  const navigate = useNavigate();
  return (
    <Box className={classes.nav}>
      <Grid>
        <img src={IMAGES.logo} alt='logo' height={60} />
        {/* <Title order={4}></Title> */}
      </Grid>
      <Button
        color='green'
        onClick={() => {
          signOut();
          navigate(`${ROUTES.ADMINLOGIN}`);
        }}
      >
        Logout
      </Button>
    </Box>
  );
};

export default memo(Header);
