import { NEWSCONSTANT } from "../../constants/News";

export const eventsApi = {
  GET_PAGINATED_EVENT: NEWSCONSTANT.newaapiprefix + "/get-paginated-events",
  GET_EVENT_ID: NEWSCONSTANT.newaapiprefix + "/get-single-event",
  CREATE_EVENT: NEWSCONSTANT.newaapiprefix + "/create-event",
  DELETE_EVENT: NEWSCONSTANT.newaapiprefix + "/delete-event",
  UPDATE_STATUS_EVENT: NEWSCONSTANT.newaapiprefix + "/deactivate-event",
  UPDATE_EVENT: NEWSCONSTANT.newaapiprefix + "/update-event",
};
