import { useMutation } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { TServerResponse } from "../../../Types";
import { apiUrls } from "../../api-urls";

const updateNews = async (data: FormData) => {
  const res: TServerResponse = await request({
    url: apiUrls.UPDATE_NEWS,
    method: "POST",
    data: data,
  });

  return res;
};

export const useUpdateNewsMutation = () => {
  return useMutation({
    mutationFn: updateNews,
  });
};
