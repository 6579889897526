import React, { memo } from "react";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import { ROUTES } from "../../enum/routes";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Outlet } from "react-router-dom";

const AdminDashboard = () => {
  const isAuthenticated = useIsAuthenticated();
  if (!isAuthenticated) {
    window.location.replace(`${ROUTES.ADMINLOGIN}`);
  }
  return (
    <>
      <Header />
      <Sidebar />
      <div style={{ marginLeft: "285px", marginTop: "4.5rem" }}>
        <Outlet />
      </div>
    </>
  );
};

export default memo(AdminDashboard);
