import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { TServerResponse } from "../../../Types";
import { apiUrls } from "../../api-urls";

interface IData {
  itemsPerPage: number;
  activePage: number;
}

export const getPaginatedNewsletterUsers = async (data: IData) => {
  const res: TServerResponse = await request({
    url:
      apiUrls.GET_SUBSCRIBED_USERS +
      "?page=" +
      data.activePage +
      "&itemPerPage=" +
      data.itemsPerPage,
  });
  return res;
};

export const useGetSubscribedNewsLetterUsers = (data: IData) => {
  return useQuery({
    queryKey: ["subscribed", "users", data],
    queryFn: () => getPaginatedNewsletterUsers(data),
  });
};
