import { TextInput, TextInputProps } from "@mantine/core";
import React from "react";
import classes from "./index.module.css";
interface ICustomTextInput {
  label: string;
  placeholder: string;
  value?: string;
  padding?: string;
  fontSize?: string;
  formHandler?: TextInputProps;
  disabled?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
}
const Input: React.FC<ICustomTextInput> = ({
  label,
  placeholder,
  formHandler,
  disabled,
  onKeyDown,
  padding,
  fontSize,
  value,
}) => {
  return (
    <TextInput
      label={label}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onKeyDown={onKeyDown}
      {...formHandler}
      w={"100%"}
      classNames={{ input: classes.input }}
      styles={{
        input: {
          padding: padding,
          fontSize: fontSize,
        },
        label: {
          fontSize: fontSize,
        },
      }}
    />
  );
};

export default Input;
