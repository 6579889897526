import React, { useMemo, useState } from "react";
import DefaultTable from "../../../components/Dynamic-Table/table/DefaultTable";
import { subscribedUsersColumns } from "../../../constants/Newsletter/SubscribedUsers";
import { useGetSubscribedNewsLetterUsers } from "../../../hooks/newsletter/query/useGetSubscribedUsers";
import { CONSTANTS } from "../../../components/Dynamic-Table/types/constants";
import { Box, Button, Flex, Text } from "@mantine/core";
import { useGetAllNewsletterFormData } from "../../../hooks/newsletter/query/useGetAllSubscribedUsers";

const SubscribedUsers = () => {
  const [pagedData, setPagedData] = useState({ total: 0 });
  const [activePage, setActivePage] = useState(1);
  const { data, isLoading } = useGetSubscribedNewsLetterUsers({
    activePage,
    itemsPerPage: CONSTANTS.PAGE_LIMIT,
  });
  const { data: allData, isLoading: allNewsletterLoading } =
    useGetAllNewsletterFormData({
      activePage: 1,
      itemsPerPage: CONSTANTS.PAGE_LIMIT,
    });
  const allNewsletterData = useMemo(() => {
    if (!allNewsletterLoading) {
      return allData;
    } else {
      return [];
    }
  }, [allNewsletterLoading, allData]);
  const convertToCSV = (data: any) => {
    if (data.length === 0) return "";

    const headers = Object.keys(data[0]).join(",");
    const rows = data.map((row: any) =>
      Object.values(row)
        .map((value) => `"${value}"`)
        .join(",")
    );

    return [headers, ...rows].join("\n");
  };

  const handleDownload = () => {
    const csvData = convertToCSV(allNewsletterData);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "subscribed_user_data.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  const newData = useMemo(() => {
    if (!isLoading && data?.status === "success") {
      data?.pageData && setPagedData(data.pageData);
      return data.data;
    } else {
      return [];
    }
  }, [isLoading, data]);
  return (
    <div>
      <Box style={{ paddingTop: "2rem" }}>
        <Flex justify={"space-between"}>
          <Text style={{ fontSize: "1.5rem", fontWeight: "400" }}>
            All Subscribed Users' Details
          </Text>
          <Button style={{ marginRight: "2rem" }} onClick={handleDownload}>
            Download
          </Button>
        </Flex>
        <Box style={{ marginTop: "1rem" }}>
          <DefaultTable
            columns={subscribedUsersColumns as TTableColumns<unknown>[]}
            data={newData}
            isLoading={isLoading}
            paginationProps={{
              setPage: setActivePage,
              totalPages: pagedData.total,
            }}
          />
        </Box>
      </Box>
    </div>
  );
};

export default SubscribedUsers;
