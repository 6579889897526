import moment from "moment";
import { TnewAndBlogGet } from "../../Types";
import ActionBar from "../../components/ActionBar/ActionBar";

export const heading: TTableColumns<TnewAndBlogGet>[] = [
  {
    key: "title",
    label: "Title",
    renderCell: (value) => (
      <div
        dangerouslySetInnerHTML={{ __html: value.title }}
        style={{ textWrap: "wrap", padding: "10px" }}
      />
    ),
    minWidth: "280px",
  },
  {
    key: "description",
    label: "Description",
    renderCell: (value) => (
      <div
        dangerouslySetInnerHTML={{ __html: value.description }}
        style={{ textWrap: "wrap", padding: "10px" }}
      />
    ),
    minWidth: "350px",
  },
  {
    key: "image",
    label: "Image",
    renderCell: (value) => (
      <img
        src={value.image}
        alt="newsImage"
        style={{ width: "100px", height: "70px" }}
      />
    ),
  },
  {
    key: "date",
    label: "Date",
    renderCell: (value) => new Date(value.date).toLocaleDateString(),
  },
  {
    key: "link",
    label: "Link",
    renderCell: (value) => (
      <a href={value.link} target="_blank">
        Link
      </a>
    ),
  },
  {
    key: "",
    label: "Actions",
    renderCell: (value) => <ActionBar value={value} />,
  },
];

export const newaapiprefix = "/admin";
