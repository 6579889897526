import { TextInput } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import React, { memo, useEffect } from "react";
import classes from "./index.module.css";

interface IProps {
  onChangeText?: (value: string) => void;
}

const TableSearch: React.FC<IProps> = ({ onChangeText }) => {
  const [value, setValue] = useDebouncedState("", 400);

  useEffect(() => {
    onChangeText && onChangeText(value);
  }, [value, onChangeText]);

  return (
    <TextInput
      className={classes.root}
      placeholder='search'
      onChange={(e) => {
        setValue(e.target.value);
      }}
    />
  );
};

export default memo(TableSearch);
