import { NEWSCONSTANT } from "../../constants/News";

export const newsletterApi = {
  GET_PAGINATED_NEWSLETTER:
    NEWSCONSTANT.newaapiprefix + "/get-paginated-newsletter",
  GET_NEWSLETTER_ID: NEWSCONSTANT.newaapiprefix + "/get-single-newsletter",
  CREATE_NEWSLETTER: NEWSCONSTANT.newaapiprefix + "/create-newsletter",
  DELETE_NEWSLETTER: NEWSCONSTANT.newaapiprefix + "/delete-newsletter",
  UPDATE_STATUS_NEWSLETTER:
    NEWSCONSTANT.newaapiprefix + "/deactivate-newsletter",
  UPDATE_NEWSLETTER: NEWSCONSTANT.newaapiprefix + "/update-newsletter",
  GET_SUBSCRIBED_USERS: NEWSCONSTANT.newaapiprefix + "/get-subscribed-users",
};
