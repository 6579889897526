export const SIDEBARCONSTANT = {
  data: [
    { link: "news", label: "News" },
    { link: "blogs", label: "Blogs" },
    { link: "stories", label: "Stories" },
    { link: "events", label: "Events" },
    { link: "research", label: "Research" },
    { link: "newsletter", label: "Newsletter" },
    { link: "contacts", label: "Contact Forms" },
  ],
};
