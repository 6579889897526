import { Flex } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import React, { memo } from "react";
import Input from "../../../components/Input/Input";
import FButton from "../../../components/Button/FButton";
import { adminLogin } from "../../../api/admin.login";
import { adminLoginValidation } from "../../../validation/admin.validation";
import FPasswordInput from "../../../components/FPasswordInput/FPasswordInput";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../enum/routes";
import { IMAGES } from "../../../assets/Images";
import { TAdminForm } from "../../../Types";

const AdminLogin = () => {
  const form = useForm<TAdminForm>({
    initialValues: {
      email: "",
      password: "",
    },
    validate: yupResolver(adminLoginValidation),
  });
  const signIn = useSignIn();
  const navigate = useNavigate();
  const handleAdminSubmit = async (e: TAdminForm) => {
    const res = await adminLogin({
      email: e.email,
      password: e.password,
    });
    if (res.statusCode === 200) {
      // console.log("login successful");
      signIn({
        auth: {
          token: res.data.token,
          type: "Bearer",
        },
        userState: {
          name: res.data.authUserState.email,
        },
      });
      sessionStorage.setItem("authToken", res.data.token);
      form.reset();
      navigate(`${ROUTES.ADMINDASHBOARD}`);
    } else {
      console.log("ERROR");
    }
  };
  return (
    <form onSubmit={form.onSubmit(handleAdminSubmit)}>
      <Flex
        direction="column"
        pt={36}
        gap={28}
        align={"center"}
        mx={"auto"}
        maw={600}
      >
        <img src={IMAGES.logo} alt="logo" width={150} />
        <Input
          label="Enter Email Id"
          placeholder="Enter Email Id"
          formHandler={form.getInputProps("email")}
          padding="24px 12px"
          fontSize="16px"
        />
        <FPasswordInput
          label="Enter Password"
          placeholder="Enter Password"
          formHandler={form.getInputProps("password")}
          padding="24px 12px"
          fontSize="16px"
        />
        <FButton label="Login" type="submit" />
      </Flex>
    </form>
  );
};

export default memo(AdminLogin);
