import React from "react";
import { useGetResearchById } from "../../../hooks/research/queries/useGetResearchById";
import { useCreateResearchMutation } from "../../../hooks/research/mutations/useCreateResearch";
import { useUpdateResearchMutation } from "../../../hooks/research/mutations/useUpdateResearch";
import { researchValidation } from "../../../validation/research.validation";
import GenericForm from "../../../components/ContentForm/GenericForm";

const ResearchAdd: React.FC = () => {
  return (
    <>
      <GenericForm
        entityName="research"
        displayName="Research"
        useCreateMutation={useCreateResearchMutation}
        useFetchById={useGetResearchById}
        useUpdateMutation={useUpdateResearchMutation}
        validationSchema={researchValidation}
      />
    </>
  );
};
export default ResearchAdd;
