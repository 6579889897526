import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { TServerResponse } from "../../../Types";
import { apiUrls } from "../../api-urls";

interface IData {
  _id: string;
}

export const getNewsletter = async (data: IData) => {
  const res: TServerResponse = await request({
    url: apiUrls.GET_NEWSLETTER_ID + `/${data._id}`,
    method: "GET",
  });
  return res;
};

export const useGetNewsletterById = (data: IData, isEditing: boolean) => {
  return useQuery({
    queryKey: ["newsletter", data._id],
    queryFn: () => getNewsletter(data),
    enabled: isEditing,
  });
};
