import React, { memo, ReactNode } from "react";
import { Box, Flex, Table } from "@mantine/core";

import { COLORS } from "../types/colors";
import TableLoading from "./components/TableLoading";
import TableSearch from "./components/TableSearch";
import { TPaging } from "../types/table/tablePaging";
import Pagination from "../Pagination/Pagination";
import classes from "./index.module.css";

interface IProps<T> {
  onChangeSearch?: (value: string) => void;
  rightComponent?: ReactNode;
  data: T[];
  columns: TTableColumns<T>[];
  isLoading: boolean;
  paginationProps?: TPaging;
}

const DefaultTable: React.FC<IProps<unknown>> = <T,>({
  data,
  columns,
  isLoading,
  paginationProps,
  onChangeSearch,
  rightComponent,
}: IProps<T>) => {
  return (
    <Box>
      <Box>
        {onChangeSearch ? (
          <TableSearch onChangeText={onChangeSearch} />
        ) : (
          <div />
        )}
        {rightComponent ? <Box>{rightComponent}</Box> : <div />}
      </Box>
      <Box>
        {!isLoading ? (
          <Box className={classes.tableContainer}>
            <Table
              verticalSpacing={"md"}
              // striped
              style={{ textAlign: "center", tableLayout: "auto" }}
              width={"100%"}
            >
              <thead>
                <tr>
                  {columns.map((item, index) => {
                    return (
                      <th
                        key={`${"_" + index}`}
                        className={classes.tHead}
                        style={{
                          textAlign: "center",
                          width: "15%",
                          minWidth: item.minWidth ?? "fit-content",
                        }}
                      >
                        {item.label}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {data.length === 0 && (
                  <tr style={{}}>
                    <td
                      colSpan={columns.length}
                      style={{ textAlign: "center", fontWeight: 500 }}
                    >
                      No Data Found
                    </td>
                  </tr>
                )}
                {data.length > 0 &&
                  data.map((item: any, index) => {
                    return (
                      <tr
                        key={item._id}
                        style={{
                          backgroundColor:
                            index % 2 ? COLORS.white : COLORS.grey,
                        }}
                      >
                        {columns.map((column, i) => {
                          return (
                            <td
                              style={{
                                width: column.minWidth,
                                whiteSpace: column.minWidth
                                  ? "normal"
                                  : "nowrap",
                                paddingTop: "6px",
                                paddingBottom: "6px",
                              }}
                              key={`${"_" + i}`}
                              className={classes.tBody}
                            >
                              {column.renderCell ? (
                                <Flex justify={"center"}>
                                  {column.renderCell(item)}
                                </Flex>
                              ) : (
                                item[column.key]
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Box>
        ) : (
          <TableLoading />
        )}
        {paginationProps && (
          <Box pb={16} px={32}>
            <Pagination
              setPage={paginationProps.setPage}
              totalPages={paginationProps.totalPages}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default memo(DefaultTable);
