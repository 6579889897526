import { NEWSCONSTANT } from "../../constants/News";

export const blogApis = {
  GET_BLOGS: NEWSCONSTANT.newaapiprefix + "/get-paginated-blogs",
  GET_BLOG_ID: NEWSCONSTANT.newaapiprefix + "/get-single-blog",
  CREATE_BLOGS: NEWSCONSTANT.newaapiprefix + "/create-blog",
  DELETE_BLOGS: NEWSCONSTANT.newaapiprefix + "/delete-blog",
  UPDATE_STATUS_BLOGS: NEWSCONSTANT.newaapiprefix + "/deactivate-blog",
  UPDATE_BLOG: NEWSCONSTANT.newaapiprefix + "/update-blog",
};
