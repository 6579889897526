import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { TServerResponse } from "../../../Types";
import { apiUrls } from "../../api-urls";

interface IData {
  itemsPerPage: number;
  activePage: number;
}

const fetchAllPages = async (data: IData) => {
  let allData: any[] = [];
  let currentPage = data.activePage;

  while (true) {
    const res: TServerResponse = await request({
      url:
        apiUrls.GET_SUBSCRIBED_USERS +
        "?page=" +
        currentPage +
        "&itemPerPage=" +
        data.itemsPerPage,
    });

    allData = [...allData, ...res.data]; // Adjust based on your response structure

    if (res.data.length < data.itemsPerPage) {
      break; // Exit loop if there are no more pages
    }

    currentPage++;
  }

  return allData;
};

export const useGetAllNewsletterFormData = (data: IData) => {
  return useQuery({
    queryKey: ["newsletter-form", "all-data", data],
    queryFn: () => fetchAllPages(data),
  });
};
