import { IEvent } from "../../Types";
import EventsActionBar from "../../components/ActionBar/EventsActionBar";

export const eventsColumns: TTableColumns<IEvent>[] = [
  {
    key: "title",
    label: "Title",
    minWidth: "20%",
    renderCell: (value) => value.cardData.title,
  },
  {
    key: "description",
    label: "Description",
    minWidth: "30%",
    renderCell: (value) => value.cardData.description,
  },
  {
    key: "cardImg",
    label: "Card Image",
    minWidth: "20%",
    renderCell: (value: any) => (
      <img src={value.cardData.cardImg} alt="Card" style={{ maxWidth: 100 }} />
    ),
  },
  {
    key: "startDate",
    label: "Start Date",
    minWidth: "15%",
    renderCell: (value) => new Date(value.startDate).toLocaleDateString(),
  },
  {
    key: "endDate",
    label: "End Date",
    minWidth: "15%",
    renderCell: (value) => new Date(value.endDate).toLocaleDateString(),
  },
  {
    key: "actions",
    label: "Actions",
    minWidth: "15%",
    renderCell: (value) => <EventsActionBar value={value} />,
  },
];
