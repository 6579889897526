import { useMutation } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { IFormData, INewsletter2, TServerResponse } from "../../../Types";
import { apiUrls } from "../../api-urls";

const updateNewsletter = async ({
  data,
  id,
}: {
  data: INewsletter2;
  id: string;
}) => {
  const res: TServerResponse = await request({
    url: apiUrls.UPDATE_NEWSLETTER + `/${id}`,
    method: "POST",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};

export const useUpdateNewsletterMutation = () => {
  return useMutation({
    mutationFn: updateNewsletter,
  });
};
