import * as yup from "yup";
const MAX_FILE_SIZE = 2 * 1024 * 1024;
export const researchValidation = yup.object().shape({
  date: yup.date().required("Date is required"),
  cardData: yup.object().shape({
    cardImg: yup
      .mixed()
      .required("Card image is required")
      .test(
        "fileSize",
        "File size is too large. Max size is 2MB",
        (value: any) => {
          if (typeof value === "string") {
            return true;
          }
          if (value && (value instanceof File || value[0] instanceof File)) {
            // Handle both single File object and FileList
            const file = value instanceof File ? value : value[0];
            return file.size <= MAX_FILE_SIZE;
          }
          return false;
        }
      ),
    title: yup.string().required("Title is required"),
    description: yup.string().required("Description is required"),
  }),
});
