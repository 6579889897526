import { Box, FileInput } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import React, { memo, useContext } from "react";
import { ImageCropPickerContext } from "./ImagePicker";

interface ICropPickerInput {
  formHandler: UseFormReturnType<any>;
  name: string;
  label: string;
  pickerRatio: number;
}

const CropPickerInput: React.FC<ICropPickerInput> = ({
  label,
  name,
  pickerRatio,
  formHandler,
}) => {
  const { setFieldValue, values, errors } = formHandler;
  const cropPicker = useContext(ImageCropPickerContext);

  const onSubmitCrop = (image: { croppedImg: Blob; filename: string }) => {
    setFieldValue(name, { image: image.croppedImg, fileName: image.filename });
  };

  const handleClick = () => {
    cropPicker.show({
      aspect: pickerRatio,
      onSaveCallback: onSubmitCrop,
    });
  };
  return (
    <Box>
      <FileInput
        onClick={handleClick}
        placeholder={values[name] ? values[name].fileName : "Choose File"}
        label={label}
        withAsterisk
        accept="image/png,image/jpeg"
        error={
          errors[name] && (
            <p style={{ color: "#fa5252", fontSize: 12 }}>{errors[name]}</p>
          )
        }
      />
    </Box>
  );
};

export default memo(CropPickerInput);
