import React, { memo, useState, useEffect } from "react";
import { Pagination } from "@mantine/core";
import { CONSTANTS } from "../types/constants/index";
import { useDebouncedValue } from "@mantine/hooks";
import { TPaging } from "../types/table/tablePaging";

const ThemePagination = (props: TPaging) => {
  const { setPage, totalPages } = props;
  const [active, setActive] = useState(1);
  const [debounce] = useDebouncedValue(active, 200);

  useEffect(() => {
    setPage(debounce);
  }, [debounce, setPage]);

  if (totalPages && totalPages <= CONSTANTS.PAGE_LIMIT) {
    return null;
  }

  return (
    <Pagination
      value={active}
      onChange={setActive}
      size={"sm"}
      total={Math.ceil(totalPages / CONSTANTS.PAGE_LIMIT)}
      radius="sm"
      mt={20}
    />
  );
};

export default memo(ThemePagination);
