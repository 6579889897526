import { NEWSCONSTANT } from "../../constants/News";

export const storiesApi = {
  GET_PAGINATED_STORIES: NEWSCONSTANT.newaapiprefix + "/get-paginated-stories",
  GET_STORY_ID: NEWSCONSTANT.newaapiprefix + "/get-single-story",
  CREATE_STORY: NEWSCONSTANT.newaapiprefix + "/create-story",
  DELETE_STORY: NEWSCONSTANT.newaapiprefix + "/delete-story",
  UPDATE_STATUS_STORY: NEWSCONSTANT.newaapiprefix + "/deactivate-story",
  UPDATE_STORY: NEWSCONSTANT.newaapiprefix + "/update-story",
};
