import React, { memo } from "react";
import { TnewAndBlogGet } from "../../Types";
import { Flex, Tooltip } from "@mantine/core";
import { IconEdit, IconEye, IconEyeOff, IconTrash } from "@tabler/icons-react";
import FButton from "../Button/FButton";
import { confirmationAlert } from "../../container/modal/confirmationAlert";
import { usedelteNewsMutation } from "../../hooks/news/mutations/useDeleteNew";
import NewsAddAndUpdateModal from "../NewsModal/NewAddModal";
import { useDisclosure } from "@mantine/hooks";
import { useUpdateStatusMutation } from "../../hooks/news/mutations/useUpdateStatus";

const ActionBar = ({ value }: { value: TnewAndBlogGet }) => {
  const { mutate, isPending } = usedelteNewsMutation();
  const { mutate: updateStatusMutate, isPending: updatePending } =
    useUpdateStatusMutation();
  const [opened, { open, close }] = useDisclosure(false);
  const handleDelete = () => {
    mutate({ _id: value._id! });
  };
  const handleUpdateStatus = () => {
    updateStatusMutate({ _id: value._id!, active: value.active });
  };
  return (
    <Flex gap={"sm"}>
      <FButton
        onClick={() => {
          confirmationAlert({
            title: "Update Status",
            msg: `Are you sure want to update the status to ${
              value.active ? "unactive" : "active"
            }`,
            labels: { cancel: "cancel", confirm: "Update" },
            onConfirm: handleUpdateStatus,
          });
        }}
        loading={updatePending}
        color='gray'
        type='button'
        px={2}
        py={3}
      >
        <Tooltip label={value.active ? "active" : "unactive"}>
          {value.active ? <IconEye /> : <IconEyeOff />}
        </Tooltip>
      </FButton>
      <NewsAddAndUpdateModal
        value={value}
        onClose={close}
        isOpened={opened}
        title='Add News'
      />
      <FButton onClick={open} color='gray' type='button' px={2} py={3}>
        <Tooltip label={"Edit"}>
          <IconEdit />
        </Tooltip>
      </FButton>
      <FButton
        onClick={() =>
          confirmationAlert({
            title: "Delete News",
            msg: "Are you sure want to delete this news?",
            labels: { cancel: "cancel", confirm: "Delete" },
            onConfirm: handleDelete,
          })
        }
        color='red'
        type='button'
        px={2}
        py={3}
        loading={isPending}
      >
        <Tooltip label={"Delete"}>
          <IconTrash />
        </Tooltip>
      </FButton>
    </Flex>
  );
};

export default memo(ActionBar);
