import React, { memo, useEffect, useState } from "react";
import { useForm, yupResolver } from "@mantine/form";
import {
  Button,
  TextInput,
  Checkbox,
  FileInput,
  Box,
  Group,
  Divider,
  Flex,
  Text,
  Textarea,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { IFormData } from "../../../Types";
import styles from "./indes.module.css";
import RichTextEditorWrapper from "../../../components/RichTextEditor/RichTextEditorWrapper";
import { useQueryClient } from "@tanstack/react-query";
import { useCreateBlogsMutation } from "../../../hooks/blogs/mutations/useCreateBlogs";
import { showNotification } from "@mantine/notifications";
import { blogValidation } from "../../../validation/blogs.validation";
import { useNavigate, useParams } from "react-router-dom";
import { useGetBlogById } from "../../../hooks/blogs/queries/useGetBlogById";
import { useUpdateBlogMutation } from "../../../hooks/blogs/mutations/useUpdateBlog";
import { useCreateStoryMutation } from "../../../hooks/stories/mutations/useCreateStory";
import { useUpdateNewsMutation } from "../../../hooks/news/mutations/useUpdateNews";
import { useUpdateStoryMutation } from "../../../hooks/stories/mutations/useUpdateStory";
import { useGetSingleStory } from "../../../hooks/stories/queries/useGetSingleStory";
import ImageCropInput2 from "../../../container/ImageCropPicker/ImageCropInput2";
import GenericForm from "../../../components/ContentForm/GenericForm";
interface Iprops {
  name: string;
}
const StoryAddForm: React.FC = () => {
  return (
    <>
      <GenericForm
        entityName="stories"
        displayName="Story"
        useCreateMutation={useCreateStoryMutation}
        useFetchById={useGetSingleStory}
        useUpdateMutation={useUpdateStoryMutation}
        validationSchema={blogValidation}
      />
    </>
  );
};
export default memo(StoryAddForm);
