import { Title } from "@mantine/core";
import React, { memo } from "react";

const WelcomeTab = () => {
  return (
    <div>
      <Title order={2} ta={"center"}>
        Welcome to the Admin Panel
      </Title>
    </div>
  );
};
export default memo(WelcomeTab);
