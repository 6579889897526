import React from "react";
import { useCreateNewsletterMutation } from "../../../hooks/newsletter/mutations/useCreateNewsletter";
import { useUpdateNewsletterMutation } from "../../../hooks/newsletter/mutations/useUpdateNewsletter";
import { useGetNewsletterById } from "../../../hooks/newsletter/query/useGetSingleNewsletter";
import { newsletterValidation } from "../../../validation/newsletter.validation";
import GenericForm from "../../../components/ContentForm/GenericForm";

const NewsletterForm: React.FC = () => {
  return (
    <>
      <GenericForm
        displayName="Newsletter"
        entityName="newsletter"
        useCreateMutation={useCreateNewsletterMutation}
        useFetchById={useGetNewsletterById}
        useUpdateMutation={useUpdateNewsletterMutation}
        validationSchema={newsletterValidation}
      />
    </>
  );
};
export default NewsletterForm;
