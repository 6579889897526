import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { TServerResponse } from "../../../Types";
import { apiUrls } from "../../api-urls";

interface IData {
  itemsPerPage: number;
  activePage: number;
}

export const getPaginatedNew = async (data: IData) => {
  const res: TServerResponse = await request({
    url:
      apiUrls.GET_NEWS +
      "?page=" +
      data.activePage +
      "&itemPerPage=" +
      data.itemsPerPage,
  });
  return res;
};

export const useGetPaginatedNews = (data: IData) => {
  return useQuery({
    queryKey: ["news", "paginated", data],
    queryFn: () => getPaginatedNew(data),
  });
};
