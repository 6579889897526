import { useMutation } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { IEvent, IFormData, TServerResponse } from "../../../Types";
import { apiUrls } from "../../api-urls";

const createEvent = async ({ data }: { data: IEvent }) => {
  const res: TServerResponse = await request({
    url: apiUrls.CREATE_EVENT,
    method: "POST",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};

export const useCreateEventMutation = () => {
  return useMutation({
    mutationFn: createEvent,
  });
};
