import React, { useMemo, useState } from "react";

import { Box, Button, Flex, Text } from "@mantine/core";
import { useGetPaginatedContactForm } from "../../hooks/contact-form/query/useGetPaginatedContactForm";
import { CONSTANTS } from "../../components/Dynamic-Table/types/constants";
import DefaultTable from "../../components/Dynamic-Table/table/DefaultTable";
import { contactFormColumns } from "../../constants/ContactForm/ContactHeading";
import { useGetAllContactFormData } from "../../hooks/contact-form/query/useGetAllContactForm";

const ContactTab = () => {
  const [pagedData, setPagedData] = useState({ total: 0 });
  const [activePage, setActivePage] = useState(1);
  const { data, isLoading } = useGetPaginatedContactForm({
    activePage,
    itemsPerPage: CONSTANTS.PAGE_LIMIT,
  });
  const { data: contact, isLoading: allDataLoading } = useGetAllContactFormData(
    {
      activePage: 1,
      itemsPerPage: 5,
    }
  );
  const allData = useMemo(() => {
    if (!allDataLoading) {
      return contact;
    } else {
      return [];
    }
  }, [allDataLoading, contact]);
  const convertToCSV = (data: any) => {
    if (data.length === 0) return "";

    const headers = Object.keys(data[0]).join(",");
    const rows = data.map((row: any) =>
      Object.values(row)
        .map((value) => `"${value}"`)
        .join(",")
    );

    return [headers, ...rows].join("\n");
  };

  const handleDownload = () => {
    const csvData = convertToCSV(allData);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "contact_form_data.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const newData = useMemo(() => {
    if (!isLoading && data?.status === "success") {
      data?.pageData && setPagedData(data.pageData);
      return data.data;
    } else {
      return [];
    }
  }, [isLoading, data]);
  return (
    <div>
      <Box style={{ paddingTop: "2rem" }}>
        <Flex justify={"space-between"}>
          <Text style={{ fontSize: "1.5rem", fontWeight: "400" }}>
            All Users' Details who contacted through the website
          </Text>
          <Button style={{ marginRight: "2rem" }} onClick={handleDownload}>
            Download
          </Button>
        </Flex>
        <Box style={{ marginTop: "1rem" }}>
          <DefaultTable
            columns={contactFormColumns as TTableColumns<unknown>[]}
            data={newData}
            isLoading={isLoading}
            paginationProps={{
              setPage: setActivePage,
              totalPages: pagedData.total,
            }}
          />
        </Box>
      </Box>
    </div>
  );
};

export default ContactTab;
