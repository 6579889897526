import { ActionIcon, Flex, Tooltip } from "@mantine/core";
import { IconEdit, IconEye, IconEyeOff, IconTrash } from "@tabler/icons-react";
import React from "react";
import { IFormData } from "../../Types";
import { memo } from "react";
import { confirmationAlert } from "../../container/modal/confirmationAlert";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import { usedelteResearchMutation } from "../../hooks/research/mutations/useDeleteResearch";
import { useUpdateStatusResearchMutation } from "../../hooks/research/mutations/useUpdateStatus";

const ResearchActionBar = ({ value }: { value: IFormData }) => {
  const { mutateAsync: deleteResearch, isPending } = usedelteResearchMutation();
  const { mutateAsync: updateStatus } = useUpdateStatusResearchMutation();
  const navigate = useNavigate();
  const handleEdit = (id: string) => {
    navigate(`/research/edit/${id}`);
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteResearch({ _id: id });
      showNotification({
        title: "Research Deleted",
        message: "Done",
        color: "green",
      });
    } catch (error) {
      showNotification({
        title: "Can't Delete Research",
        message: "Server Error",
        color: "red",
      });
    }
  };

  const handleToggleActive = async (id: string, isActive: boolean) => {
    try {
      // console.log("isActive Value", isActive);
      await updateStatus({ _id: id, isActive: isActive });
      showNotification({
        title: "Status Updated",
        message: "Research Updated Successfully",
        color: "green",
      });
    } catch (error) {
      showNotification({
        title: "Can't Update Status",
        message: "Internal Server Error",
        color: "red",
      });
    }
  };
  return (
    <div>
      <Flex gap="xs" justify="center">
        <Tooltip label="Edit">
          <ActionIcon onClick={() => handleEdit(value._id ?? "")}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete">
          <ActionIcon
            color="red"
            onClick={() =>
              confirmationAlert({
                title: "Delete Research",
                msg: "Are you sure want to delete this Research?",
                labels: { cancel: "cancel", confirm: "Delete" },
                onConfirm() {
                  handleDelete(value._id ?? "");
                },
              })
            }
            type="button"
            px={2}
            py={3}
            loading={isPending}
          >
            <IconTrash />
          </ActionIcon>
        </Tooltip>
        <Tooltip label={value.isActive ? "Deactivate" : "Activate"}>
          <ActionIcon
            color="gray"
            onClick={() => handleToggleActive(value._id ?? "", value.isActive)}
          >
            {value.isActive ? <IconEye /> : <IconEyeOff />}
          </ActionIcon>
        </Tooltip>
      </Flex>
    </div>
  );
};

export default memo(ResearchActionBar);
