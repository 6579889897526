import React, { memo, useMemo, useState, useRef, useEffect } from "react";
import ImageCropPicker from "./ImageCropPicker";

const defaultValues: TImageCropPickerObj = {
  show: () => {},
};

export const ImageCropPickerContext =
  React.createContext<TImageCropPickerObj>(defaultValues);
export const ImageCropPickerConsumer = ImageCropPickerContext.Consumer;

type Props = {
  children?: React.ReactNode;
};

const initials: TCropPicker = {
  aspect: 16 / 9,
};

const ImagePicker = (props: Props) => {
  const [alertProps, setAlertProps] = useState<TCropPicker>(initials);
  const [open, setOpen] = useState(false);
  const cropPickerRef = useRef<TImageCropPickerObj>(defaultValues);

  const alert = useMemo(() => {
    return {
      show: (alertData: TCropPicker) => {
        setAlertProps(alertData);
        setOpen(true);
      },
    };
  }, []);

  useEffect(() => {
    cropPickerRef.current = alert;
  }, [alert]);

  return (
    <ImageCropPickerContext.Provider value={alert}>
      {props.children}
      {open && (
        <ImageCropPicker data={alertProps} open={open} close={setOpen} />
      )}
    </ImageCropPickerContext.Provider>
  );
};

export default memo(ImagePicker);
