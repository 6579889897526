import React, { memo, useEffect, useState } from "react";
import {
  FileInput,
  Button,
  Modal,
  Box,
  Text,
  Group,
  Image,
} from "@mantine/core";
import Cropper from "react-easy-crop";
import { UseFormReturnType } from "@mantine/form";
import { getCroppedImg } from "./utils/getCropImg";

interface ImageCropInputProps {
  form: UseFormReturnType<any>;
  name: string;
  label: string;
  accept: string;
  aspectRatio?: number;
}

const ImageCropInput2: React.FC<ImageCropInputProps> = ({
  form,
  name,
  label,
  accept,
  aspectRatio = 4 / 3,
}) => {
  const [imageSrc, setImageSrc] = useState<string | undefined>();
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [cropArea, setCropArea] = useState<any>(null);
  const [opened, setOpened] = useState<boolean>(false);
  const [originalFileName, setOriginalFileName] = useState<string | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(false);
  const inputProps = form.getInputProps(name);

  useEffect(() => {
    // If form value changes externally, update the preview accordingly
    if (inputProps.value instanceof File) {
      const reader = new FileReader();
      reader.onload = () => {
        setCroppedImage(reader.result as string);
      };
      reader.readAsDataURL(inputProps.value);
    } else if (typeof inputProps.value === "string") {
      setCroppedImage(inputProps.value);
    } else {
      setCroppedImage(null);
    }
  }, [inputProps.value]);

  const onFileChange = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImageSrc(reader.result as string);
      setOpened(true);
    };
    setOriginalFileName(file.name);
    reader.readAsDataURL(file);
  };

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCropArea(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    setLoading(true);
    try {
      if (!imageSrc || !cropArea) return;
      const croppedImageBlob = await getCroppedImg(imageSrc, cropArea);
      const croppedImageUrl = URL.createObjectURL(croppedImageBlob);
      setCroppedImage(croppedImageUrl);

      const file = new File(
        [croppedImageBlob],
        `cropped-${originalFileName}` || "image.jpg",
        {
          type: croppedImageBlob.type,
        }
      );
      form.setFieldValue(name, file);
      setOpened(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleFileInputChange = (file: File | null) => {
    if (file) {
      onFileChange(file);
    } else {
      // Handle the case when the file is cleared
      handleRemoveImage();
    }
  };

  const handleRemoveImage = () => {
    form.setFieldValue(name, null);
    setCroppedImage(null);
  };

  const errors = form.errors[name];

  return (
    <div>
      <FileInput
        clearable={true}
        label={label}
        accept={accept}
        onChange={handleFileInputChange}
        error={errors ? <Text color="red">{errors}</Text> : null}
        placeholder={croppedImage ? "Change Image" : "Upload Image"}
      />
      {croppedImage && (
        <Box mt="sm">
          <Image src={croppedImage} alt="Cropped" width={200} height={200} />
          {/* <Button
            mt="sm"
            color="red"
            onClick={handleRemoveImage}
            variant="light"
          >
            Remove Image
          </Button> */}
        </Box>
      )}
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Crop Image"
        size="xl"
        centered
      >
        <Box style={{ position: "relative", width: "100%", height: 400 }}>
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={aspectRatio}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </Box>
        <Group mt="md">
          <Button variant="default" onClick={() => setOpened(false)}>
            Cancel
          </Button>
          <Button onClick={showCroppedImage} loading={loading}>
            Crop
          </Button>
        </Group>
      </Modal>
    </div>
  );
};

export default memo(ImageCropInput2);
