import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/dates/styles.css";
import "./App.css";
import AuthProvider from "react-auth-kit";
import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import { RouterProvider } from "react-router-dom";
import createStore from "react-auth-kit/createStore";
import { appRouter } from "./routes/appRoutes";
import "@mantine/tiptap/styles.css";
import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ImagePicker from "./container/ImageCropPicker/ImagePicker";
const store = createStore({
  authName: "_auth",
  authType: "cookie",
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === "https:",
});
export const queryClient = new QueryClient();
function App() {
  return (
    <AuthProvider store={store}>
      <MantineProvider>
        <Notifications />
        <ModalsProvider>
          <QueryClientProvider client={queryClient}>
            <ImagePicker>
              <RouterProvider router={appRouter} />
            </ImagePicker>
          </QueryClientProvider>
        </ModalsProvider>
      </MantineProvider>
    </AuthProvider>
  );
}

export default App;
