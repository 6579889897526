import { IFormData, IUsers } from "../../Types";
import BlogActionBar from "../../components/ActionBar/BlogActionBar";

export const contactFormColumns: TTableColumns<IUsers>[] = [
  {
    key: "firstName",
    label: "First Name",
    minWidth: "40px",
    renderCell: (value) => value.firstName,
  },
  {
    key: "lastname",
    label: "Last Name",
    minWidth: "30%",
    renderCell: (value) => value.lastName,
  },
  {
    key: "email",
    label: "Email",
    minWidth: "20%",
    renderCell: (value) => value.email,
  },
  {
    key: "phone",
    label: "Phone Number",
    minWidth: "20%",
    renderCell: (value) => (value?.phone ? value?.phone : "NA"),
  },
  {
    key: "country",
    label: "Country",
    minWidth: "20%",
    renderCell: (value) => (value?.country ? value?.country : "NA"),
  },
  {
    key: "state",
    label: "State",
    minWidth: "20%",
    renderCell: (value) => (value?.state ? value?.state : "NA"),
  },
  {
    key: "city",
    label: "City",
    minWidth: "20%",
    renderCell: (value) => (value?.city ? value?.city : "NA"),
  },
  {
    key: "organization",
    label: "Organization",
    minWidth: "20%",
    renderCell: (value) => (value?.organization ? value?.organization : "NA"),
  },
  {
    key: "tag",
    label: "Tag",
    minWidth: "20%",
    renderCell: (value) =>
      value.tag && value.tag.length > 0 ? value.tag.join(", ") : "NA",
  },
  {
    key: "linkedIn",
    label: "LinkedIn Url",
    minWidth: "20%",
    renderCell: (value) => (value?.linkedIn ? value?.linkedIn : "NA"),
  },
  {
    key: "iAmLookingFor",
    label: "Looking For",
    minWidth: "20%",
    renderCell: (value) => (value?.iAmLookingFor ? value?.iAmLookingFor : "NA"),
  },
  {
    key: "iWouldLikeToContributeTo",
    label: "Contribution",
    minWidth: "20%",
    renderCell: (value) =>
      value.iWouldLikeToContributeTo &&
      value.iWouldLikeToContributeTo.length > 0
        ? value.iWouldLikeToContributeTo.join(", ")
        : "NA",
  },
  {
    key: "iWouldLikeToWorkWithYou",
    label: "Type of Work",
    minWidth: "20%",
    renderCell: (value) =>
      value.iWouldLikeToWorkWithYou && value.iWouldLikeToWorkWithYou.length > 0
        ? value.iWouldLikeToWorkWithYou.join(", ")
        : "NA",
  },
  {
    key: "anythingElse",
    label: "Additional Info",
    minWidth: "20%",
    renderCell: (value) => (value?.anythingElse ? value?.anythingElse : "NA"),
  },
];
