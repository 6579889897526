import * as yup from "yup";

export const newAndBlogsValidation = yup.object().shape({
  title: yup
    .string()
    .required()
    .min(6, "Title Should have atleast 6 character"),
  description: yup.string().required(),
  image: yup.string(),
  date: yup.string().required(),
  link: yup.string().required().url(),
  active: yup.boolean().required(),
});
