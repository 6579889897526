import {
  Modal,
  Button,
  TextInput,
  Textarea,
  Flex,
  Grid,
  Checkbox,
  CheckIcon,
} from "@mantine/core";
import { FormEvent, memo, useEffect, useState } from "react";
import { TModal, TnewAndBlog } from "../../Types";
import { useForm, yupResolver } from "@mantine/form";
import { newAndBlogsValidation } from "../../validation/news&blogs.validation";
import { DateInput } from "@mantine/dates";
import CropPickerInput from "../../container/ImageCropPicker/CropPickerInput";
import { useSubmitNewsMutation } from "../../hooks/news/mutations/useSubmitNews";
import moment from "moment";
import { showNotification } from "@mantine/notifications";
import { IconCalendar } from "@tabler/icons-react";
import { useUpdateNewsMutation } from "../../hooks/news/mutations/useUpdateNews";
import { useQueryClient } from "@tanstack/react-query";

const NewAddAndUpdateModal = ({
  onClose,
  onOpen,
  isOpened,
  title,
  submitloading,
  value,
}: TModal) => {
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const { mutateAsync, isPending: addPending, error } = useSubmitNewsMutation();
  const { isPending, mutateAsync: updateMutate } = useUpdateNewsMutation();

  useEffect(() => {
    setLoading(isPending || addPending);
  }, [isPending, addPending]);

  useEffect(() => {
    if (value?.date) {
      const parsedDate = moment(value?.date, "MMMM DD,YYYY");
      const dateObject = parsedDate.toDate();
      const validDate = value?.date ? new Date(value?.date) : new Date();
      const isValidDate = !isNaN(validDate.getTime());
      form.setFieldValue("date", validDate ? validDate : new Date());
    }
  }, []);

  const form = useForm<TnewAndBlog>({
    mode: "controlled",
    initialValues: {
      title: value?.title ?? "",
      description: value?.description ?? "",
      cardImg: { fileName: value?.image ?? "", image: {} as Blob },
      date: new Date(),
      link: value?.link ?? "",
      active: value?.active ?? true,
    },
    validate: yupResolver(newAndBlogsValidation),
  });

  const onSubmitNews = async (
    e: TnewAndBlog,
    eve: FormEvent<HTMLFormElement> | undefined
  ) => {
    eve?.preventDefault();
    const formData = new FormData(eve?.currentTarget);

    formData.append("title", e.title);
    formData.append("description", e.description);
    formData.append("link", e.link);
    formData.append("active", String(e.active));
    formData.append("date", e.date.toISOString());
    if (value?._id) {
      // for updating

      if (
        e.cardImg.fileName.split("/").pop() !== value.image?.split("/").pop()
      ) {
        // console.log("update");
        formData.append("cardImg", e.cardImg.image, e.cardImg.fileName);
      } else {
        formData.append("preImage", e.cardImg.fileName);
      }
      formData.append("_id", value._id);
      const resp = await updateMutate(formData);
      if (resp.status === "success") {
        form.reset();
        queryClient.invalidateQueries({ queryKey: ["news", "paginated"] });
        showNotification({
          title: "News Updated Successfully",
          message: "done",
          color: "green",
        });
        onClose();
      }
    } else {
      formData.append("cardImg", e.cardImg.image, e.cardImg.fileName);
      // for creating news
      const res = await mutateAsync(formData);
      if (res.status == "success") {
        form.reset();
        showNotification({
          title: "News Added Successfully",
          message: "done",
          color: "green",
        });
        onClose();
      }
    }
    queryClient.invalidateQueries({ queryKey: ["news", "paginated"] });
    if (error) {
      console.log("error", error);
    }
  };
  return (
    <Modal opened={isOpened} size={"xl"} onClose={onClose} title={"Add News"}>
      <form onSubmit={form.onSubmit(onSubmitNews)}>
        <Flex gap={"md"} direction={"column"}>
          <TextInput
            {...form.getInputProps("title")}
            key={form.key("title")}
            label="Title"
            placeholder="Title"
          />
          <Textarea
            {...form.getInputProps("description")}
            key={form.key("description")}
            radius="md"
            label="Description"
            placeholder="Describe ..."
            autosize
            minRows={3}
          />
          <TextInput
            {...form.getInputProps("link")}
            key={form.key("link")}
            label="Link"
            placeholder="Link"
          />
          <Grid>
            <Grid.Col span={6}>
              <CropPickerInput
                name="cardImg"
                label="Upload (ratio: 293 X 173)"
                formHandler={form}
                pickerRatio={293 / 210}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <DateInput
                label="Date"
                {...form.getInputProps("date")}
                withAsterisk
                placeholder="Pick a Date"
              />
            </Grid.Col>
          </Grid>
          <Checkbox
            icon={CheckIcon}
            label="Active Status"
            {...form.getInputProps("active")}
            key={form.key("active")}
            checked={value?.active}
            defaultChecked
          />
          <Button type="submit" loading={loading}>
            {value?._id ? "Update News" : "Add News"}
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};
export default memo(NewAddAndUpdateModal);
