import { NEWSCONSTANT } from "../../constants/News";

export const researchApis = {
  GET_RESEARCHS: NEWSCONSTANT.newaapiprefix + "/get-paginated-research",
  GET_RESEARCH_ID: NEWSCONSTANT.newaapiprefix + "/get-single-research",
  CREATE_RESEARCH: NEWSCONSTANT.newaapiprefix + "/create-research",
  DELETE_RESEARCH: NEWSCONSTANT.newaapiprefix + "/delete-research",
  UPDATE_STATUS_RESEARCH: NEWSCONSTANT.newaapiprefix + "/deactivate-research",
  UPDATE_RESEARCH: NEWSCONSTANT.newaapiprefix + "/update-research",
};
