import React, { memo, useState } from "react";
import { Group } from "@mantine/core";
import classes from "./Sidebar.module.css";
import { Link, useNavigate } from "react-router-dom";
import { SIDEBARCONSTANT } from "../../constants/Sidebar";

const Sidebar = () => {
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const links = SIDEBARCONSTANT.data.map((item) => (
    <Link
      className={classes.link}
      to={""}
      data-active={item.label === active || undefined}
      key={item.label}
      onClick={(event) => {
        event.preventDefault();
        setActive(item.label);
        navigate(item.link, { replace: false });
      }}
    >
      <span>{item.label}</span>
    </Link>
  ));

  return (
    <nav className={classes.navbar}>
      <div className={classes.navbarMain}>
        <Group className={classes.header} justify='space-between'>
          Dashboard
        </Group>
        {links}
      </div>
    </nav>
  );
};
export default memo(Sidebar);
