import NewsLetterActionBar from "../../components/ActionBar/NewsLetterActionBar";
import { INewsletter2, IUsers, SubscribedUsers } from "../../Types";

export const subscribedUsersColumns: TTableColumns<SubscribedUsers>[] = [
  {
    key: "firstname",
    label: "First Name",
    minWidth: "20%",
    renderCell: (value) => value.firstname,
  },
  {
    key: "lastname",
    label: "Last Name",
    minWidth: "15%",
    renderCell: (value) => value.lastname,
  },
  {
    key: "email",
    label: "Email",
    minWidth: "15%",
    renderCell: (value) => value.email,
  },
  {
    key: "phone",
    label: "Phone Number",
    minWidth: "20%",
    renderCell: (value) => (value?.phone ? value?.phone : "NA"),
  },
  {
    key: "country",
    label: "Country",
    minWidth: "20%",
    renderCell: (value) => (value?.country ? value?.country : "NA"),
  },
  {
    key: "state",
    label: "State",
    minWidth: "20%",
    renderCell: (value) => (value?.state ? value?.state : "NA"),
  },
  {
    key: "city",
    label: "City",
    minWidth: "20%",
    renderCell: (value) => (value?.city ? value?.city : "NA"),
  },
  {
    key: "organization",
    label: "Organization",
    minWidth: "20%",
    renderCell: (value) => (value?.organization ? value?.organization : "NA"),
  },
];
