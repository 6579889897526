import { useMutation, useQueryClient } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { TServerResponse } from "../../../Types";
import { apiUrls } from "../../api-urls";

interface IData {
  _id: string;
  active: boolean;
}

const updateStaus = async (data: IData) => {
  const res: TServerResponse = await request({
    url: apiUrls.UPDATE_STATUS,
    method: "POST",
    data: data,
  });
  return res;
};

export const useUpdateStatusMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateStaus,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["news"] });
      return true;
    },
  });
};
