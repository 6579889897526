import * as yup from "yup";
const MAX_FILE_SIZE = 2 * 1024 * 1024;
export const eventValidation = yup.object().shape({
  startDate: yup.date().required("Start Date is required"),
  endDate: yup
    .date()
    .required("End Date is required")
    .min(
      yup.ref("startDate"),
      "End Date must be greater than or equal to Start Date"
    ),
  cardData: yup.object().shape({
    cardImg: yup
      .mixed()
      .required("Card image is required")
      .test(
        "fileSize",
        "File size is too large. Max size is 2MB",
        (value: any) => {
          if (typeof value === "string") {
            return true;
          }
          if (value && (value instanceof File || value[0] instanceof File)) {
            // Handle both single File object and FileList
            const file = value instanceof File ? value : value[0];
            return file.size <= MAX_FILE_SIZE;
          }
          if (value === null) {
            return true;
          }
          return false;
        }
      ),
    title: yup.string().required("Title is required"),
    description: yup.string().required("Description is required"),
  }),
});
