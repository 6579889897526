import { request } from "../services/axios.service";
import { TAdminForm } from "../Types";

export const adminLogin = async ({ email, password }: TAdminForm) => {
  const response = await request({
    method: "POST",
    url: "/admin-login",
    data: { username: email, password },
  }).then((e) => {
    return e;
  });
  return response;
};
