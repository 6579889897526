import { Button, Flex, Title } from "@mantine/core";
import FButton from "../../components/Button/FButton";
import { useNavigate } from "react-router-dom";
import DefaultTable from "../../components/Dynamic-Table/table/DefaultTable";
import { useGetPaginatedBlogs } from "../../hooks/blogs/queries/useGetPaginatedBlogs";
import { blogColumns } from "../../constants/Blogs/BlogHeading";
import { memo, useMemo, useState } from "react";
import { CONSTANTS } from "../../components/Dynamic-Table/types/constants";
import { newsletterColumns } from "../../constants/Newsletter/NewsletterHeading";
import { useGetPaginatedNewsLetter } from "../../hooks/newsletter/query/useGetPaginatedNewsletter";

const NewsletterTab = () => {
  const [pagedData, setPagedData] = useState({ total: 0 });
  const [activePage, setActivePage] = useState(1);
  const { data, isLoading } = useGetPaginatedNewsLetter({
    activePage,
    itemsPerPage: 20,
  });
  const navigate = useNavigate();
  const newData = useMemo(() => {
    if (!isLoading && data?.status === "success") {
      data?.pageData && setPagedData(data.pageData);
      return data.data;
    } else {
      return [];
    }
  }, [isLoading, data]);

  return (
    <div>
      <Flex
        justify={"space-between"}
        pb={20}
        pt={10}
        pr={"xl"}
        align={"center"}
      >
        <Title order={5}>All Newsletters</Title>
        <Flex style={{ width: "46%", justifyContent: "flex-end", gap: "1rem" }}>
          <FButton
            label="Add New"
            color="blue"
            textAlign={"end"}
            width="100"
            onClick={() => navigate("/newsletter/add-newsletter")}
          />
          <Button
            onClick={() => navigate("/newsletter/view-subscribed-users")}
            style={{
              height: "auto",
              borderRadius: "6px",
              backgroundColor: "#40c057",
            }}
          >
            View Subscribed Users
          </Button>
        </Flex>
      </Flex>

      <DefaultTable
        columns={newsletterColumns as TTableColumns<unknown>[]}
        data={newData}
        isLoading={isLoading}
        paginationProps={{
          setPage: setActivePage,
          totalPages: pagedData.total,
        }}
      />
    </div>
  );
};

export default memo(NewsletterTab);
