import { showNotification } from "@mantine/notifications";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { TResponse } from "../Types";
import { getCookie } from "./get.cookies";

const client = axios.create({ baseURL: `${process.env.REACT_APP_BASE_URL}` });

export const request = async (options: AxiosRequestConfig) => {
  const token = getCookie("_auth");
  client.defaults.headers.common.authorization = `Bearer ${token}`;
  const onSuccess = (response: AxiosResponse) => ({
    ...response.data,
    statusCode: response.status,
  });
  const onError = (error: TResponse) => {
    if (error.response.data.status === "error") {
      showNotification({
        title: error.response?.data?.title,
        message: error.response?.data?.message,
        color: "red",
      });
    }

    return error.response;
  };

  try {
    const response = await client(options);
    return onSuccess(response);
  } catch (error: unknown) {
    console.log(error);
    return onError(error as TResponse);
  }
};
